/* You can add global styles to this file, and also import other style files */
@import '~@fortawesome/fontawesome-free/css/all.css';
@import 'ag-grid-community/dist/styles/ag-grid.css';
@import 'ag-grid-community/dist/styles/ag-theme-balham.css';
@import 'variables';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Muli', 'Helvetica Neue', sans-serif;
}

ag-grid-angular {
  width: 100%;
  // accessibility feature for users who zooms in on screen sizes
  height: clamp(320px, 70vh, 900px);
  margin-bottom: 20px;
}

.mat-snack-bar-container {
  &.success {
    background: #28a745;
    color: white;
  }

  &.default {
    background: #343a40;
    color: white;
  }

  &.info {
    background: #17a2b8;
    color: white;
  }

  &.error {
    background: #dc3545;
    color: white;
  }
}

.mat-menu-panel {
  border-radius: 0 !important;
}

.mat-raised-button {
  border-radius: 0 !important;
}

.mat-green {
  background-color: green;
  color: #fff;
}

.mat-blue {
  background-color: #17a2b8;
  color: #fff;
}

.mat-brown-orange {
  background-color: #db3700;
  color: #fff;
}

.heading {
  padding: 20px 0;
}

.mat-card.gray {
  background: #e3e3e3;
}

.mat-dialog-content {
  h3,
  h4,
  h5 {
    margin: 0 0 5px 0;
  }

  .button-row {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .ag-header-cell-text {
    color: black;
  }
}

.img-responsive {
  width: 100%;
  height: auto;
}

.no-margin {
  margin: 0 !important;
}

.mat-error {
  color: #db3700;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #db3700;
}

.button-row {
  margin-top: 20px;
  margin-bottom: 20px;
}

.ag-header-cell-text {
  color: black;
}

.home .mat-card-subtitle {
  margin: 5px 0;
  font-size: 1em;
  color: #2c2c2c;
}

.homeCard {
  width: 70%;
  margin-left: 1%;
  margin-bottom: 2px;
  cursor: pointer;
  color: #fff !important;
}

.homeCard:hover {
  box-shadow: 0 8px 8px 8px rgba(0, 0, 0, 0.2), 0 8px 8px 0 rgba(0, 0, 0, 0.14), 0 8px 8px 0 rgba(0, 0, 0, 0.12) !important;
  transform: translate3d(0, 0, 0);
  transition: background 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

.cardTitle {
  text-align: center;
  margin-right: 0px !important;
  min-width: 100px;
  -width: 100% !important;
}

.cardIcon {
  align-items: center;
  justify-content: center;
  text-align: center;
  display: block !important;
  margin-left: auto;
  margin-right: auto;
}

.grid-parent-div {
  width: 100%;
  // height: 100%;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.mb-1 {
  margin-bottom: 8px;
}

.mb-2 {
  margin-bottom: 16px;
}

.mb-3 {
  margin-bottom: 24px;
}

.mb-4 {
  margin-bottom: 32px;
}

.mb-5 {
  margin-bottom: 40px;
}

.mt-1 {
  margin-top: 8px;
}

.mt-2 {
  margin-top: 16px;
}

.full-width {
  width: 100% !important;
}

.horizontal-spacer {
  margin-left: auto;
}

.vertical-spacer {
  height: 15px;
}

#date-picker {
  height: 34px;
  text-align: center;
  min-width: 14rem;
  font-size: 16px;
  border: solid 1px grey;

  flex: 1 1 auto;

  @media screen and (min-width: 600px) {
    flex: 0 0 40%;
  }
}

/*
.mat-form-field {
  &.no-gap .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  &.width-auto .mat-form-field-infix {
    width: auto !important;
  }

  &:not(.mat-form-field-has-label) {
    .mat-form-field-infix {
      top: -0.2em;
      border-top-width: 0;
    }

    .mat-form-field-flex {
      padding-top: 0px;
    }
  }

  .md-drppicker.double {
    min-width: 660px;
  }
}

@media (max-width: 660px) {
  .mat-form-field {
    .md-drppicker {
      min-width: auto;
    }
  }
}
*/

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner 0.8s linear infinite;
}

.response-toggle-buttons {
  .mat-button-toggle {
    color: white;

    &.na-button {
      background-color: darkgrey;
    }

    &.cold-button {
      background-color: lightskyblue;
    }

    &.neutral-button {
      background-color: rosybrown;
    }

    &.warm-button {
      background-color: darkorange;
    }

    &.hot-button {
      background-color: orangered;
    }
  }

  .mat-button-toggle-checked {
    color: white !important;

    &.na-button {
      box-shadow: inset 0px 0px 0px 7px hsl(0, 0%, 58%);
    }

    &.cold-button {
      box-shadow: inset 0px 0px 0px 7px hsl(203, 92%, 67%);
    }

    &.neutral-button {
      box-shadow: inset 0px 0px 0px 7px hsl(0, 25%, 57%);
    }

    &.warm-button {
      box-shadow: inset 0px 0px 0px 7px hsl(33, 100%, 42%);
    }

    &.hot-button {
      box-shadow: inset 0px 0px 0px 7px hsl(16, 100%, 42%);
    }
  }
}

.icon-fa-plus {
  margin-right: 5px;
  vertical-align: unset !important; //overrides fontawesome's value
}

// Override ngx-daterangepicker's timepicker font size.
select:is(.hourselect, .minuteselect).select-item {
  font-size: 13.333px !important;

  option {
    font-size: 13.333px !important;
  }
}

.md-drppicker td.active,
.md-drppicker td.active:hover,
.md-drppicker .btn:not(.btn-default),
.md-drppicker .ranges ul li button.active {
  background-color: $primary !important;
}

.autocomplete-option {
  white-space: normal !important;
  height: auto !important;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.create-edit-program-dialog {
  .mat-dialog-content {
    max-height: unset;
  }

  @media (max-width: 959px) {
    .mat-dialog-container {
      padding: 1em;
    }
  }

  mat-form-field {
    line-height: normal;

    .mat-form-field-wrapper {
      margin: 0;
      padding-bottom: 0;

      .mat-form-field-flex {
        padding: 0;
        margin-top: 0;

        .mat-form-field-outline {
          top: 0;
          background-color: white;
          border-radius: 5px;
        }

        .mat-form-field-infix {
          top: 0;
          padding: 0.1em 0.25em;
          border-top-width: 0;

          input.mat-input-element {
            margin-top: 0;
          }

          .mat-form-field-label-wrapper {
            top: 0;
            padding-top: 0;
          }
        }

        .mat-form-field-suffix {
          top: 2px;
          right: 5px;

          mat-icon {
            --size: 18px;
            font-size: var(--size);
            height: var(--size);
            width: var(--size);
          }
        }
      }
    }
  }

  .program-details {
    .field {
      mat-label {
        width: 10em;

        @media (max-width: 599px) {
          width: 7em;
        }
      }

      mat-form-field {
        flex: auto;
        min-width: 18em;

        @media (max-width: 599px) {
          min-width: 15em;

          .mat-form-field-infix {
            width: auto;
          }
        }
      }
    }
  }

  .session-container {
    .duration {
      .mat-form-field-infix {
        padding: 0.1em 0.5em !important;
      }
    }

    .mat-form-field-appearance-outline {
      .mat-select-arrow-wrapper {
        transform: translateY(-5%) !important;
      }
    }

    .mat-flat-button {
      line-height: 1.75em;
      padding: 0 0.5em;

      .icon-fa-plus {
        margin-right: 1px;
      }
    }
  }

  .md-drppicker {
    position: fixed !important;
    top: calc(50% - 180px) !important;
    left: calc(50% - 143px) !important;

    &.hidden,
    &.shown {
      transition: none !important;
    }
  }
}

.lookahead-input {
  .mat-option {
    line-height: 1.25;
    padding: 0 0.25em;
  }
}

.duration-select {
  min-width: 2em !important;
  margin-left: 1em !important;

  .mat-option {
    line-height: 1.25 !important;
    height: unset !important;
    padding: 0 0.25em;
  }
}

/* Enables the text cursor when hovering over ag-Grid cells to indicate that the cell content is selectable and can be copied */
.ag-cell-value {
  cursor: text;
}
